import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BASE_URL from "../Component/Baseurl";
import NavbarVisitor from "../Component/NavbarVisitor";
import defaultevent from "../Component/Img/defaultevent.webp";
import { Link } from "react-router-dom"; // Import Link

const AllEvents = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch all events
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/fetchAllEvents`);
        if (response.data.success) {
          setEvents(response.data.events);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
        toast.error("Error fetching events");
      }
    };
    fetchEvents();
  }, []);

  return (
    <>
      <NavbarVisitor />

      <div className="container">
        <div className="row mt-3 wdiv">
          <div className="head-label">
            <h4 className="text-start">All Trending Events</h4>
          </div>

          <div className="row">
            {events.map((event) => (
              <div className="col-md-4" key={event.event_unique_id}>
                <div>
                  <img
                    src={event.image_url || defaultevent}
                    alt="Event"
                    className="img-fluid"
                  />
                  <div className="aqs1 text-start mt-2">
                    {/* Add the Link component here */}
                    <Link to={`/event-for/${event.event_slug}`} className="text-decoration-none">
                      {event.event_name}
                    </Link>
                  </div>
                  <div className="clk2 text-start">{event.event_unique_id}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="row mt-3"></div>
      </div>

      <ToastContainer />
    </>
  );
};

export default AllEvents;