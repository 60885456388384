import React, { useState, useEffect } from "react";
import { useEvent } from "../../contexts/EventContext"; // Assumed event context
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import axiosInstance from '../../axiosInstance'; // Axios instance to handle API requests

const EventVenue = () => {
  const { eventId } = useEvent();
  const [eventMode, setEventMode] = useState("physical");
  const [venue, setVenue] = useState("");
  const [timezone, setTimezone] = useState("");
  const [joiningLink, setJoiningLink] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch venue details by event ID
  useEffect(() => {
    const fetchVenueDetails = async () => {
      try {
        const response = await axiosInstance.get(`/fetch_event_venue_by_event_id/${eventId}`);
        const { eventVenue } = response.data;

        // Populate state with fetched data
        setEventMode(eventVenue.event_mode || "physical"); // Default to physical if undefined
        setVenue(eventVenue.venue || "");
        setTimezone(eventVenue.timezone || "");
        setJoiningLink(eventVenue.joining_link || "");
      } catch (error) {
        console.error("Error fetching venue details:", error.response ? error.response.data : error.message);
        // toast.error("Failed to fetch venue details.");
      }
    };

    fetchVenueDetails();
  }, [eventId]); // Run effect when eventId changes

  const handleEventModeChange = (e) => {
    setEventMode(e.target.value);
  };

  // Update event function
  const handleUpdateEvent = async (e) => {
    e.preventDefault();

    // Prepare the payload for the API request
    const payload = {
      event_unique_id: eventId,
      venue,
      timezone,
      joining_link: joiningLink,
      event_mode: eventMode,
    };

    // Log the payload to debug
    // console.log("Payload being sent:", payload);

    try {
      const response = await axiosInstance.post('/update_event_venue', payload);
      toast.success("Event venue updated successfully!");
    } catch (error) {
      console.error("Error updating event venue:", error.response ? error.response.data : error.message);
      toast.error("Error updating event venue.");
    }
  };

  return (
    <div className="container-dash p-0 position-relative">
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    <h3 className="text-start h3txt">Event Venue Details</h3>
                    <p className="text-body-tertiary lh-sm mb-2 p1txt">Specify your event venue details below</p>
                    <hr />
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active w-100" id="home" role="tabpanel">
                        {error ? (
                          <p className="text-danger">{error}</p>
                        ) : (
                          <form onSubmit={handleUpdateEvent}>
                            <div className="row text-start">
                              <div className="col-md-3">
                                <div className="evntovr">
                                  <Link to={`/event/${eventId}`} className="text-white">Event Basic Details</Link>
                                </div>
                                <div className="evntovr">
                                  <Link to={`/event/${eventId}/EventTimeZone`} className="text-white">Event Time Details</Link>
                                </div>
                                <div className="evntovr">
                                  <Link to={`/event/${eventId}/eventvenue`} className="text-white">Event Venue Details</Link>
                                </div>
                              </div>

                              <div className="col-md-9">
                                <div className="form-group mb-3">
                                  <label>Event Mode</label>
                                  <div>
                                    <label className="me-3">
                                      <input
                                        type="radio"
                                        value="physical"
                                        checked={eventMode === "physical"}
                                        onChange={handleEventModeChange}
                                      /> Physical
                                    </label>
                                    <label className="me-3">
                                      <input
                                        type="radio"
                                        value="virtual"
                                        checked={eventMode === "virtual"}
                                        onChange={handleEventModeChange}
                                      /> Virtual
                                    </label>
                                    <label>
                                      <input
                                        type="radio"
                                        value="hybrid"
                                        checked={eventMode === "hybrid"}
                                        onChange={handleEventModeChange}
                                      /> Hybrid
                                    </label>
                                  </div>
                                </div>

                                {/* Show Physical options */}
                                {(eventMode === "physical" || eventMode === "hybrid") && (
                                  <>
                                    <div className="form-group mb-3">
                                      <label>Venue</label>
                                      <select
                                        className="form-control"
                                        value={venue}
                                        onChange={(e) => setVenue(e.target.value)}
                                      >
                                        <option value="">Select Venue</option>
                                        <option value="Venue 1">Venue 1</option>
                                        <option value="Venue 2">Venue 2</option>
                                        <option value="Venue 3">Venue 3</option>
                                      </select>
                                    </div>

                                    <div className="form-group mb-3">
                                      <label>Google Map</label>
                                      <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387190.2799198646!2d-74.25986771878452!3d40.69767006334825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c250b1414379d3%3A0x37f4b8c99833f7d5!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1617027025831!5m2!1sen!2sin"
                                        width="100%"
                                        height="200"
                                        allowFullScreen=""
                                        loading="lazy"
                                      ></iframe>
                                    </div>
                                  </>
                                )}

                                {/* Show Virtual options */}
                                {(eventMode === "virtual" || eventMode === "hybrid") && (
                                  <>
                                    <div className="form-group mb-3">
                                      <label>Timezone</label>
                                      <select
                                        className="form-control"
                                        value={timezone}
                                        onChange={(e) => setTimezone(e.target.value)}
                                      >
                                        <option value="">Select Timezone</option>
                                        <option value="IST">IST (Indian Standard Time)</option>
                                        <option value="EST">EST (Eastern Standard Time)</option>
                                        <option value="CST">CST (Central Standard Time)</option>
                                        <option value="PST">PST (Pacific Standard Time)</option>
                                      </select>
                                    </div>

                                    <div className="form-group mb-3">
                                      <label>Joining Link</label>
                                      <input
                                        type="url"
                                        className="form-control"
                                        value={joiningLink}
                                        onChange={(e) => setJoiningLink(e.target.value)}
                                        placeholder="Enter Joining Link"
                                        required
                                      />
                                    </div>
                                  </>
                                )}
                                <button type="submit" className="btn btnn org-btn text-light w-100">Update Venue</button>
                              </div>
                              
                            </div>
                            
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default EventVenue;
