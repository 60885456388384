import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from '../Component/Baseurl';
import NavbarOrganiser from '../Component/NavbarOrganiser';
import FirstSidebarOrganiser from '../Component/FirstSidebarOrganiser';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CreateEvent = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeEvent, setActiveEvent] = useState('EXHIBITION');
  const [eventType, setEventType] = useState('In-Person');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [eventName, setEventName] = useState('');
  const [organizedBy, setOrganizedBy] = useState('');
  const [category, setCategory] = useState('');
  const [frequency, setFrequency] = useState('');
  const [currentStep, setCurrentStep] = useState(1); // To track the current step
  const [redirect, setRedirect] = useState(false);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleFrequencyChange = (value) => {
    setFrequency(value);
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      const randomToken = localStorage.getItem('random_token');
      const organiserId = localStorage.getItem('organiser_id');

      if (randomToken && organiserId) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    };

    checkAuthentication();
  }, []);

  if (isAuthenticated === false) {
    return <Navigate to="/Login" />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const setEventFormat = (value) => {
    setActiveEvent(value);
  };

  const handleNextStep = () => {
    if (currentStep === 1) {
      // Validate step 1 fields if needed
      setCurrentStep(2);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(1);
  };

  // industry list
  const categories = ['Technology', 'Health', 'Education', 'Science'];

  const handleCategoryClick = (selectedCategory) => {
    const categoryArray = category.split(',').map((cat) => cat.trim());
    
    if (categoryArray.includes(selectedCategory)) {
      const updatedCategory = categoryArray.filter((cat) => cat !== selectedCategory).join(', ');
      setCategory(updatedCategory);
    } else {
      setCategory(category ? `${category}, ${selectedCategory}` : selectedCategory);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    let isValid = true;
    if (!eventName || !organizedBy || !category || !startDate || !endDate || !frequency) {
      toast.error('Please fill in all required fields');
      isValid = false;
    }
    
    if (!isValid) return;
    
    const organiserId = localStorage.getItem('organiser_id');
    const eventData = {
      eventName,
      eventType,
      activeEvent,
      organizedBy,
      category,
      startDate,
      endDate,
      frequency,
      organiserId,
    };
    
    try {
      const response = await fetch(`${BASE_URL}add-event`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventData),
      });
    
      const responseData = await response.json();
      
      if (response.ok) {
        if (responseData.message === 'Event with this slug already exists') {
          toast.error('Event with this slug already exists');
        } else {
          toast.success('Event created successfully');
          setRedirect(true); // Trigger redirection
        }
      } else {
        toast.error(responseData.message || 'Failed to create event');
      }
    } catch (error) {
      toast.error('Error: ' + error.message);
    }
  };


  // Conditional redirection
  if (redirect) {
    return <Navigate to="/Add" />;
  }

  return (
    <>
      <ToastContainer />

      {/* <FirstSidebarOrganiser /> */}
      {/* <NavbarOrganiser /> */}
      <div className="  p-0 position-relative">
        <div className="background-overlay"></div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div className="container my-3">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card shadow-lg rounded">
                    <div className="card-body p-4">
                      {currentStep === 1 && (
                        <div>
                          <h2 className="text-center mb-4">Select Event Format</h2>
                          <div className="row">
                            <div className="col-md-4">
                              <div
                                className={`py-3 pb-1 ${activeEvent === 'EXHIBITION' ? 'activeDiv' : 'eventDiv'}`}
                                onClick={() => setEventFormat('EXHIBITION')}
                                data-value="EXHIBITION"
                              >
                                <h4 className={activeEvent === 'EXHIBITION' ? 'text-white' : 'text-dark'}>EXHIBITION</h4>
                                <p className={activeEvent === 'EXHIBITION' ? 'text-white' : 'text-dark'}>
                                  Showcasing your Products, Services, Offerings, etc.
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div
                                className={`py-3 pb-1 ${activeEvent === 'CONFERENCE' ? 'activeDiv' : 'eventDiv'}`}
                                onClick={() => setEventFormat('CONFERENCE')}
                                data-value="CONFERENCE"
                              >
                                <h4 className={activeEvent === 'CONFERENCE' ? 'text-white' : 'text-dark'}>CONFERENCE</h4>
                                <p className={activeEvent === 'CONFERENCE' ? 'text-white' : 'text-dark'}>
                                  Showcasing your Products, Services, Offerings, etc.
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div
                                className={`py-3 pb-1 ${activeEvent === 'WORKSHOP' ? 'activeDiv' : 'eventDiv'}`}
                                onClick={() => setEventFormat('WORKSHOP')}
                                data-value="WORKSHOP"
                              >
                                <h4 className={activeEvent === 'WORKSHOP' ? 'text-white' : 'text-dark'}>WORKSHOP</h4>
                                <p className={activeEvent === 'WORKSHOP' ? 'text-white' : 'text-dark'}>
                                  Hands-on Experience with Demonstrations, Training, etc.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-5">
                        <div className="col-md-3">
                        <p className="text-center mb-4 p-underline">
                          What is the type of your event?
                        </p>
                        </div>
                        <div className="col-md-9 d-flex justify-content-around">
                        <div className=" mb-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="eventTypeVirtual"
                              name="eventType"
                              value="Virtual"
                              checked={eventType === 'Virtual'}
                              onChange={() => setEventType('Virtual')}
                            />
                            <label className="form-check-label" htmlFor="eventTypeVirtual">
                              Virtual
                            </label>
                          </div>

                          <div className="  mb-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="eventTypeInPerson"
                              name="eventType"
                              value="In-Person"
                              checked={eventType === 'In-Person'}
                              onChange={() => setEventType('In-Person')}
                            />
                            <label className="form-check-label" htmlFor="eventTypeInPerson">
                              In-Person
                            </label>
                          </div>

                          <div className="  mb-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="eventTypeHybrid"
                              name="eventType"
                              value="Hybrid"
                              checked={eventType === 'Hybrid'}
                              onChange={() => setEventType('Hybrid')}
                            />
                            <label className="form-check-label" htmlFor="eventTypeHybrid">
                              Hybrid
                            </label>
                          </div>

                        </div>
                         
                      </div>
                          <div className="mt-5 d-flex justify-content-center">
                            <button
                              type="button"
                              className="btn org-btn text-white"
                              onClick={handleNextStep}
                            >
                              Next
                            </button>
                          </div>
 

                        </div>

                        
                      )}

                      {currentStep === 2 && (
                        <div>
                          <h2 className="text-center mb-4">Event Details</h2>
                          <div className="mb-3 text-start">
                            <label htmlFor="event_name" className="mb-2">
                              Event Name<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control inputgroup2"
                              id="event_name"
                              value={eventName}
                              onChange={(e) => setEventName(e.target.value)}
                            />
                          </div>
                          <div className="mb-3 text-start">
                            <label htmlFor="organized_by" className="mb-2">
                              Organized By<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control inputgroup2"
                              id="organized_by"
                              value={organizedBy}
                              onChange={(e) => setOrganizedBy(e.target.value)}
                            />
                          </div>

                          <div className="mb-3 text-start">
      <label htmlFor="category" className="mb-2">
        Category / Industry<span className="text-danger">*</span>
      </label>
      <textarea
        className="form-control textarea1"
        id="category"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        rows="3" readOnly placeholder="Select Category/Industry From Below List"
      ></textarea>
      <div className="mt-2 txt-spn">
        {categories.map((cat) => (
          <span
            key={cat}
            className={`example-category text-dark okp ${category.includes(cat) ? 'text-decoration-line-through' : ''}`}
            onClick={() => handleCategoryClick(cat)}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          >
            {cat}
          </span>
        ))}
      </div>
    </div>



                          <div className="mb-3 text-start">
                            <div className="row">
                              <div className="col-md-4 text-start">
                                <label htmlFor="start_date" className="mb-2">
                                  Start Date<span className="text-danger">*</span>
                                </label>
                                <DatePicker
                                  selected={startDate}
                                  onChange={handleStartDateChange}
                                  dateFormat="yyyy-MM-dd"
                                  className="inputgroup2 w-100"
                                  placeholderText="Select Start Date"
                                  minDate={new Date()}
                                />
                              </div>
                              <div className="col-md-4 text-start">
                                <label htmlFor="end_date" className="mb-2">
                                  End Date<span className="text-danger">*</span>
                                </label>
                                <DatePicker
                                  selected={endDate}
                                  onChange={handleEndDateChange}
                                  minDate={startDate}
                                  dateFormat="yyyy-MM-dd"
                                  className="inputgroup2 w-100"
                                  placeholderText="Select End Date"
                                />
                              </div>

                              <div className="col-md-4 text-start">
                              <label htmlFor="frequency" className="mb-2">
                              Frequency<span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select inputgroup2"
                              value={frequency} id="frequency11"
                              onChange={(e) => handleFrequencyChange(e.target.value)}
                            >
                              <option value="" disabled>
                                Select Frequency
                              </option>

        <option value="One Time Event">One Time Event</option>
        <option value="Every Week">Every Week</option>
        <option value="Every Month">Every Month</option>
        <option value="Once In 3 Month">Once In 3 Month</option>
        <option value="Once In 6 Month">Once In 6 Month</option>
        <option value="Once In a year">Once In a year</option>
        <option value="Once In a 2 years">Once In a 2 years</option>
        <option value="Once In a 3 years">Once In a 3 years</option>
        <option value="Once In a 4 years">Once In a 4 years</option>
        <option value="Once In a 5 years">Once In a 5 years</option>
 
                            </select>
                              </div>


                            </div>
                          </div>
                           
                          <div className="mt-5 d-flex justify-content-between">
                            <button
                              type="button"
                              className="btn org-btn text-white"
                              onClick={handlePreviousStep}
                            >
                              Previous
                            </button>
                            <button
                              type="button"
                              className="btn org-btn text-white"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEvent;
