import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { toast } from 'react-toastify'; // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS

const FirstSidebarOrganiser = () => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [activeLink, setActiveLink] = useState(null);
  const navigate = useNavigate(); // Create a navigate function

  const handleNavbarToggle = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  const handleLogout = () => {
    // Clear specific local storage items
    localStorage.removeItem('random_token');
    localStorage.removeItem('organiser_id');

    // Optionally, show a toast notification
    toast.success('Logged out successfully!');

    // Redirect to login page or another route after a short delay
    setTimeout(() => {
      navigate('/login'); // Replace '/login' with your desired route
    }, 1000); // Delay of 1 second before redirecting
  };


  return (
    <>
      <header className="header" id="header">
        <div className="header_toggle" onClick={handleNavbarToggle}>
          <i className={`bx ${isNavbarVisible ? 'bx-x bx-menu' : 'bx-menu'}`} id="header-toggle">button</i>
        </div>
        <div className="header_img_container">
          <div className="header_img">
            <img src="https://i.imgur.com/hczKIze.jpg" alt="User Avatar" />
          </div>
          <button className="logout_button" onClick={handleLogout}>Logout</button>
        </div>
      </header>
      <div className={`l-navbar ${isNavbarVisible ? 'show' : ''}`} id="nav-bar">
        <nav className="nav">
          <div>
            <a
              href="#"
              className={`nav_logo ${activeLink === 0 ? 'active' : ''}`}
              onClick={() => handleLinkClick(0)}
            >
              <i className='bx bx-layer nav_logo-icon'></i>
              <span className="nav_logo-name">Evventoz</span>
            </a>
            <div className="nav_list navlist1">
              <Link
                to="/create-event"
                className={`nav_link ${activeLink === 1 ? 'active' : ''}`}
                onClick={() => handleLinkClick(1)}
              >
                <i className='bx bx-grid-alt nav_icon'></i>
                <span className="nav_name">Create Event</span>
              </Link>

              <Link
                to="/event-user"
                className={`nav_link ${activeLink === 2 ? 'active' : ''}`}
                onClick={() => handleLinkClick(2)}
              >
                <i className='bx bx-grid-alt nav_icon'></i>
                <span className="nav_name">User & Access</span>
              </Link>

              <Link
                to="/calander"
                className={`nav_link ${activeLink === 3 ? 'active' : ''}`}
                onClick={() => handleLinkClick(3)}
              >
                <i className='bx bx-grid-alt nav_icon'></i>
                <span className="nav_name">Event Calander</span>
              </Link>


            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default FirstSidebarOrganiser;