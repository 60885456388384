import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faFile, faUser, faChevronUp, faChevronDown, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faGripLines } from '@fortawesome/free-solid-svg-icons'; // Example icon

const NavbarOrganiser = ({ onNavigate }) => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [activeLink, setActiveLink] = useState(null);
  const [dropdowns, setDropdowns] = useState({
    overview: false,
    customForm: false,
    leads: false,
  });
  const { event_unique_id } = useParams();

  const handleNavbarToggle = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  const handleLinkClick = (index, path) => {
    setActiveLink(index);
    if (onNavigate) {
      onNavigate(path);
    }
  };

  const handleDropdownToggle = (dropdown) => {
    setDropdowns({
      ...dropdowns,
      [dropdown]: !dropdowns[dropdown],
    });
  };

  return (
    <>
      <header className="header" id="header">
        <div className="header_toggle" onClick={handleNavbarToggle}>
          <FontAwesomeIcon icon={isNavbarVisible ? faChevronDown : faChevronUp} id="header-toggle" />
        </div>
        <div className="header_img">
          <img src="https://i.imgur.com/hczKIze.jpg" alt="User Avatar" />
        </div>
      </header>
      <div className={`l-navbar ${isNavbarVisible ? 'show' : ''}`} id="nav-bar">
        <nav className="nav">
          <div className="ovrflw">
            <Link 
              to={`/Dashboard_Organiser`} 
              className={`nav_logo ${activeLink === 0 ? 'active' : ''}`} 
              onClick={() => handleLinkClick(0, `/Dashboard_Organiser`)}
            >
              <FontAwesomeIcon icon={faLayerGroup} className='nav_logo-icon' />
              <span className="nav_logo-name">All Events</span>
            </Link>

            {/* Event Overview Dropdown */}
            <div className="nav_dropdown">
              <div className="nav_dropdown_header" onClick={() => handleDropdownToggle('overview')}>
                <FontAwesomeIcon icon={faFile} className='nav_icon' />
                <span className="nav_name mfthed">Event Overview</span>
                <FontAwesomeIcon icon={dropdowns.overview ? faMinus : faPlus} className='nav_dropdown_icon' />
              </div>
              <div className={`nav_dropdown_content ${dropdowns.overview ? 'show' : ''}`}>
                <Link 
                  to={`/event/${event_unique_id}`} 
                  className={`nav_link text-start ${activeLink === 1 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(1, `/event/${event_unique_id}`)}
                >
                  <span className="nav_name mfthed2">Event Overview</span>
                </Link> 

                {/* <Link 
                  to={`/event/${event_unique_id}/EventOverview2`} 
                  className={`nav_link text-start ${activeLink === 19 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(19, `/event/${event_unique_id}/EventOverview2`)}
                >
                  <span className="nav_name mfthed2">Event Overview 2</span>
                </Link>  */}

                


                <Link 
                  to={`/event/${event_unique_id}/session-and-agenda`} 
                  className={`nav_link text-start ${activeLink === 2 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(2, `/event/${event_unique_id}/session-and-agenda`)}
                >
                  <span className="nav_name mfthed2">Session & Agenda</span>
                </Link>
                <Link 
                  to={`/event/${event_unique_id}/event_image`} 
                  className={`nav_link text-start ${activeLink === 3 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(3, `/event/${event_unique_id}/event_image`)}
                >
                  <span className="nav_name mfthed2">Event Image</span>
                </Link>
                <Link 
                  to={`/event/${event_unique_id}/exibitors`} 
                  className={`nav_link text-start ${activeLink === 4 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(4, `/event/${event_unique_id}/exibitors`)}
                >
                  <span className="nav_name mfthed2">Exibitors</span>
                </Link>
                <Link 
                  to={`/event/${event_unique_id}/speakers`} 
                  className={`nav_link text-start ${activeLink === 5 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(5, `/event/${event_unique_id}/speakers`)}
                >
                  <span className="nav_name mfthed2">Speakers</span>
                </Link>
                <Link 
                  to={`/event/${event_unique_id}/sponser`} 
                  className={`nav_link text-start ${activeLink === 6 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(6, `/event/${event_unique_id}/sponser`)}
                >
                  <span className="nav_name mfthed2">Sponser</span>
                </Link>
                <Link 
                  to={`/event/${event_unique_id}/partner`} 
                  className={`nav_link text-start ${activeLink === 7 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(7, `/event/${event_unique_id}/partner`)}
                >
                  <span className="nav_name mfthed2">Partner</span>
                </Link>
              </div>
            </div>

            {/* Create Custom Form Dropdown */}
            <div className="nav_dropdown">
              <div className="nav_dropdown_header" onClick={() => handleDropdownToggle('customForm')}>
                <FontAwesomeIcon icon={faFile} className='nav_icon' />
                <span className="nav_name text-start mfthed">Create Custom Form</span>
                <FontAwesomeIcon icon={dropdowns.customForm ? faMinus : faPlus} className='nav_dropdown_icon' />
              </div>
              <div className={`nav_dropdown_content ${dropdowns.customForm ? 'show' : ''}`}>
                <Link 
                  to={`/event/${event_unique_id}/add-custom-form`} 
                  className={`nav_link text-start ${activeLink === 8 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(8, `/event/${event_unique_id}/add-custom-form`)}
                >
                  <span className="nav_name mfthed2">Add Custom Form</span>
                </Link>
              </div>
            </div>

            {/* Leads Dropdown */}
            <div className="nav_dropdown">
              <div className="nav_dropdown_header" onClick={() => handleDropdownToggle('leads')}>
                <FontAwesomeIcon icon={faFile} className='nav_icon' />
                <span className="nav_name mfthed">Leads</span>
                <FontAwesomeIcon icon={dropdowns.leads ? faMinus : faPlus} className='nav_dropdown_icon' />
              </div>
              <div className={`nav_dropdown_content ${dropdowns.leads ? 'show' : ''}`}>
                <Link 
                  to={`/event/${event_unique_id}/delegate-leads`} 
                  className={`nav_link text-start ${activeLink === 9 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(9, `/event/${event_unique_id}/delegate-leads`)}
                >
                  <span className="nav_name mfthed2">Delegate Leads</span>
                </Link>
                <Link 
                  to={`/event/${event_unique_id}/speaker-leads`} 
                  className={`nav_link text-start ${activeLink === 10 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(10, `/event/${event_unique_id}/speaker-leads`)}
                >
                  <span className="nav_name mfthed2">Speaker Leads</span>
                </Link>
                <Link 
                  to={`/event/${event_unique_id}/sponser-leads`} 
                  className={`nav_link text-start ${activeLink === 11 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(11, `/event/${event_unique_id}/sponser-leads`)}
                >
                  <span className="nav_name mfthed2">Sponser Leads</span>
                </Link>
                <Link 
                  to={`/event/${event_unique_id}/visitor-leads`} 
                  className={`nav_link text-start ${activeLink === 12 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(12, `/event/${event_unique_id}/visitor-leads`)}
                >
                  <span className="nav_name mfthed2">Visitor Leads</span>
                </Link>
                <Link 
                  to={`/event/${event_unique_id}/exhibitor-leads`} 
                  className={`nav_link text-start ${activeLink === 13 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(13, `/event/${event_unique_id}/exhibitor-leads`)}
                >
                  <span className="nav_name mfthed2">Exhibitor Leads</span>
                </Link>
              </div>
            </div>


            {/* Report Dropdown */}
            <div className="nav_dropdown">
              <div className="nav_dropdown_header" onClick={() => handleDropdownToggle('report')}>
                <FontAwesomeIcon icon={faFile} className='nav_icon' />
                <span className="nav_name mfthed">Report</span>
                <FontAwesomeIcon icon={dropdowns.report ? faMinus : faPlus} className='nav_dropdown_icon' />
              </div>
              <div className={`nav_dropdown_content ${dropdowns.report ? 'show' : ''}`}>
                <Link 
                  to={`/event/${event_unique_id}/analytics-report`} 
                  className={`nav_link text-start ${activeLink === 9 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(9, `/event/${event_unique_id}/analytics-report`)}
                >
                  <span className="nav_name mfthed2">Analytics Report</span>
                </Link>
                 
                 
                 
                 
              </div>
            </div>


            {/* Commuication Dropdown */}
            <div className="nav_dropdown">
              <div className="nav_dropdown_header" onClick={() => handleDropdownToggle('rating')}>
                <FontAwesomeIcon icon={faFile} className='nav_icon' />
                <span className="nav_name mfthed">Communication</span>
                <FontAwesomeIcon icon={dropdowns.rating ? faMinus : faPlus} className='nav_dropdown_icon' />
              </div>
              <div className={`nav_dropdown_content ${dropdowns.rating ? 'show' : ''}`}>
                <Link 
                  to={`/event/${event_unique_id}/all-rating-review`} 
                  className={`nav_link text-start ${activeLink === 20 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(20, `/event/${event_unique_id}/all-rating-review`)}
                >
                  <span className="nav_name mfthed2">Ratings & Review</span>
                </Link>
                <Link 
                  to={`/event/${event_unique_id}/event-follow`} 
                  className={`nav_link text-start ${activeLink === 21 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(21, `/event/${event_unique_id}/event-follow`)}
                >
                  <span className="nav_name mfthed2">Event Follow</span>
                </Link>

                <Link 
                  to={`/event/${event_unique_id}/event-calander`} 
                  className={`nav_link text-start ${activeLink === 22 ? 'active' : ''}`} 
                  onClick={() => handleLinkClick(22, `/event/${event_unique_id}/event-calander`)}
                >
                  <span className="nav_name mfthed2">Event Calander</span>
                </Link>
                
              </div>
            </div>


          </div>
        </nav>
      </div>
    </>
  );
};

export default NavbarOrganiser;
