import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import { useEvent } from '../../contexts/EventContext'; 
import { Link, useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormBuilder = () => {
  const { eventId } = useEvent();
  const [fetchedData, setFetchedData] = useState([]);
  const [formError, setFormError] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); // New state for search input
  const { event_unique_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(`Fetching data for eventId: ${eventId}`);
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/fetch_custom_form_byEvent/${eventId}`);
        console.log('Fetched data:', response.data);
        setFetchedData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setFormError('Error fetching forms. Please try again.');
      }
    };

    if (eventId) {
      fetchData();
    } else {
      console.error('Invalid eventId');
    }
  }, [eventId]);

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/delete_custom_form/${id}`);
          setFetchedData(fetchedData.filter((form) => form.id !== id));
          toast.success('Form deleted successfully!');
        } catch (error) {
          console.error('Error deleting form:', error);
          setFormError('Error deleting the form. Please try again.');
        }
      }
    });
  };

  const handleEdit = (id) => {
    navigate(`/edit_form/${id}`);
  };

  // Filter forms based on the search term
  const filteredForms = fetchedData.filter((form) =>
    form.formName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container-dash p-0 position-relative">
      <ToastContainer />
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <h1 className="mb-0 h1-cl text-start">All Forms</h1>
              </div>
              <div className="col-md-8">
                <div className="text-start">
                  <span className="tab-btn mx-1">
                    <Link to={`/event/${event_unique_id}/delegate-custom-form`} className="tab-btn-a">Delegate Form</Link>
                  </span>
                  <span className="tab-btn mx-1">
                    <Link to={`/event/${event_unique_id}/sponsor-custom-form`} className="tab-btn-a">Sponsor Form</Link>
                  </span>
                  <span className="tab-btn mx-1">
                    <Link to={`/event/${event_unique_id}/speaker-custom-form`} className="tab-btn-a">Speaker Form</Link>
                  </span>
                  <span className="tab-btn mx-1">
                    <Link to={`/event/${event_unique_id}/visitor-custom-form`} className="tab-btn-a">Visitor Form</Link>
                  </span>
                  <span className="tab-btn mx-1">
                    <Link to={`/event/${event_unique_id}/exhibitor-custom-form`} className="tab-btn-a">Exhibitor Form</Link>
                  </span>
                </div>
              </div>

              {/* Search input field */}
              <div className="col-md-12 mt-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by form name..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    {formError && <div className="alert alert-danger mb-3">{formError}</div>}
                    {filteredForms.length > 0 ? (
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Form Name</th>
                            <th scope="col">Form For</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredForms.map((form) => (
                            <tr key={form.id}>
                              <td>{form.formName}</td>
                              <td>{form.form_for}</td>
                              <td>{new Date(form.created_at).toLocaleString()}</td>
                              <td>
                                <button className="btn btn-primary" onClick={() => handleEdit(form.id)}>Edit</button>
                              </td>
                              <td>
                                <button className="btn btn-danger" onClick={() => handleDelete(form.id)}>Delete</button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>No forms found.</p>
                    )}
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormBuilder;