import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from '../../axiosInstance'; // Adjust the import based on your project structure
import { toast, ToastContainer } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import { useEvent } from '../../contexts/EventContext';

const AddExhibitorsForm = ({ handleClose }) => {
  const { eventId } = useEvent(); // Get eventId from context
  const [formData, setFormData] = useState({
    name: '',
    designation: '',
    company: '',
    email: '',
    phone: '',
    boothNo: '',
    image: null
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'image' ? files[0] : value
    }));
  };

  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    document.getElementById('imageUploadInput').click();
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    const requiredFields = ['name', 'designation'];
    const missingFields = requiredFields.filter(field => !formData[field]);

    if (missingFields.length > 0) {
      missingFields.forEach(field => {
        toast.error(`${capitalizeFirstLetter(field)} is required.`);
      });
      return; // Stop form submission if there are missing fields
    }

    const data = new FormData();
    data.append('eventId', eventId); // Ensure eventId is appended
    data.append('name', formData.name);
    data.append('designation', formData.designation);
    data.append('company', formData.company);
    data.append('email', formData.email);
    data.append('phone', formData.phone);
    data.append('boothNo', formData.boothNo);
    if (formData.image) {
      data.append('image', formData.image);
    }

    try {
      await axiosInstance.post('/add_event_exhibitor', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('Exhibitor added successfully!'); // Show success toastr message
      setFormData({
        name: '',
        designation: '',
        company: '',
        email: '',
        phone: '',
        boothNo: '',
        image: null
      }); // Clear form data
      document.getElementById('exhibitorImage').value = ''; // Clear file input
      if (handleClose) handleClose(); // Ensure handleClose is a function before calling
    } catch (error) {
      console.error('Error adding exhibitor:', error);
      toast.error('Error adding exhibitor. Please try again.'); // Show error toastr message
      // Handle the error as needed
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="container-dash p-0 position-relative">
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1 className="h1-cl text-start">Add Exhibitors</h1>
                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    <form onSubmit={handleSubmit} className="text-start">
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="exhibitorName" className="form-label lable-cl">Name</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="exhibitorName"
                              name="name"
                              className="form-control"
                              placeholder="Enter Exhibitor's name"
                              value={formData.name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="exhibitorDesignation" className="form-label lable-cl">Designation</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="exhibitorDesignation"
                              name="designation"
                              className="form-control"
                              placeholder="Enter Designation"
                              value={formData.designation}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="exhibitorCompany" className="form-label lable-cl">Company</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="exhibitorCompany"
                              name="company"
                              className="form-control"
                              placeholder="Enter Company name"
                              value={formData.company}
                              onChange={handleInputChange}
                               
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="exhibitorEmail" className="form-label lable-cl">Email</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="email"
                              id="exhibitorEmail"
                              name="email"
                              className="form-control"
                              placeholder="Enter Email"
                              value={formData.email}
                              onChange={handleInputChange}
                               
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="exhibitorPhone" className="form-label lable-cl">Phone</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="tel"
                              id="exhibitorPhone"
                              name="phone"
                              className="form-control"
                              placeholder="Enter Phone Number"
                              value={formData.phone}
                              onChange={handleInputChange}
                               
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="exhibitorBoothNo" className="form-label lable-cl">Booth No</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="exhibitorBoothNo"
                              name="boothNo"
                              className="form-control"
                              placeholder="Enter Booth Number"
                              value={formData.boothNo}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label className="form-label lable-cl">Image</label>
                          </div>
                          <div className="col-md-9">
                            <div className="image-upload-container">
                              <input
                                type="file"
                                id="imageUploadInput"
                                name="image"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                              />
                              <button
                                type="button"
                                className="btn org-btn text-light w-75"
                                onClick={handleButtonClick}
                              >
                                Upload
                              </button>
                              {imagePreview && (
                                <img
                                  src={imagePreview}
                                  alt="Preview"
                                  className="img-thumbnail mt-2"
                                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-center">
                        <button type="submit" className="btn org-btn text-light w-25">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Add ToastContainer to your component tree */}
    </div>
  );
};

export default AddExhibitorsForm;
