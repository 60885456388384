import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance';
import { useEvent } from '../../contexts/EventContext';

const EventImage = () => {
  const { eventId } = useEvent();
  const [bannerImage, setBannerImage] = useState(null);
  const [fullBannerImage, setFullBannerImage] = useState(null);
  const [floorPlanImage, setFloorPlanImage] = useState(null);
  const [brochurePdf, setBrochurePdf] = useState(null);

  useEffect(() => {
    document.title = 'Upload Event Files'; // Set the page title here
  }, []);

  const handleFileChange = (event, setter) => {
    setter(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (bannerImage) formData.append('bannerImage', bannerImage);
    if (fullBannerImage) formData.append('fullBannerImage', fullBannerImage);
    if (floorPlanImage) formData.append('floorPlanImage', floorPlanImage);
    if (brochurePdf) formData.append('brochurePdf', brochurePdf);
    
    formData.append('eventUniqueId', eventId); // Append eventUniqueId

    try {
      const response = await axiosInstance.put('/Event_image_upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Upload success:', response.data);
      // Handle success response
    } catch (error) {
      console.error('Upload error:', error);
      // Handle error response
    }
  };

  return (
    <div className="container-dash p-0 position-relative">
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    <h1>Upload Event Files</h1>
                     
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="bannerImage" className="form-label">Event Banner Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="bannerImage"
                          accept="image/*"
                          onChange={(e) => handleFileChange(e, setBannerImage)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="fullBannerImage" className="form-label">Event Full Banner Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="fullBannerImage"
                          accept="image/*"
                          onChange={(e) => handleFileChange(e, setFullBannerImage)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="floorPlanImage" className="form-label">Event Floor Plan Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="floorPlanImage"
                          accept="image/*"
                          onChange={(e) => handleFileChange(e, setFloorPlanImage)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="brochurePdf" className="form-label">Event Brochure (PDF)</label>
                        <input
                          type="file"
                          className="form-control"
                          id="brochurePdf"
                          accept=".pdf"
                          onChange={(e) => handleFileChange(e, setBrochurePdf)}
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">Upload</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventImage;