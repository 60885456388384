import React from 'react';
import './App.css';
import './Component/css/common.css';
import './Component/css/style.css';
import './Component/css/input.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Registration from './Organiser/Registration';  
import VerifyEmailOrganiser from './Organiser/VerifyEmailOrganiser';
import DashboardOrganiser from './Organiser/DashboardOrganiser';
import Login from './Organiser/Login';
import CreateEvent from './Organiser/CreateEvent';
import Logout from './Organiser/Logout';
import Event_dashboard from './Organiser/Event_dashboard';
import { EventProvider } from './contexts/EventContext';
import UserAccess from './Organiser/UserAccess';
import EventCalendar from './Component/EventsComponent/EventCalander';
import UserAccessAdd from './Organiser/UserAccessAdd';

// Visitor 
import Index from './Visitor/Index';
import Events from './Visitor/Events';
import AllEvents from './Visitor/AllEvents';
import DelegateForm from './Visitor/DelegateForm';
import SponserFormVisitor from './Visitor/SponserFormVisitor';
import SpeakerFormVisitor from './Visitor/SpeakerFormVisitor';
import VisitorFormVisitor from './Visitor/VisitorFormVisitor';
import ExhibitorFormVisitor from './Visitor/ExhibitorFormVisitor';





function App() {
  return (
    <EventProvider>
      <BrowserRouter>
        <AnimatedRoutes />
      </BrowserRouter>
    </EventProvider>
  );
}

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <div className="App bg-common" id="body-pd">
      <Routes location={location}>
        <Route path="/" element={<Registration />} /> 
        <Route path="/VerifyEmailOrganiser" element={<VerifyEmailOrganiser />} /> 
        <Route path="/Login" element={<Login />} /> 
        <Route path="/Dashboard_Organiser" element={<DashboardOrganiser />} /> 
        <Route path="/create-event" element={<CreateEvent />} /> 
        <Route path="/logout" element={<Logout />} /> 
        <Route path="/event/:event_unique_id/*" element={<Event_dashboard />} />
        <Route path="/event-user" element={<UserAccess />} /> 
        <Route path="/add-access" element={<UserAccessAdd />} /> 
        <Route path="/calander" element={<EventCalendar />} /> 

        

        <Route path="/home" element={<Index />} /> 
        <Route path="/event-for/:slug" element={<Events />} />

        <Route path="/all-events" element={<AllEvents />} /> 
        <Route path="/delegate/:event_unique_id" element={<DelegateForm />} /> 
        <Route path="/sponser/:event_unique_id" element={<SponserFormVisitor />} /> 
        <Route path="/speaker/:event_unique_id" element={<SpeakerFormVisitor />} /> 
        <Route path="/visitor/:event_unique_id" element={<VisitorFormVisitor />} /> 
        <Route path="/exhibitor/:event_unique_id" element={<ExhibitorFormVisitor />} /> 

        
        
        
        </Routes>
    </div>
  );
}

export default App;