import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BASE_URL from "../Component/Baseurl";
import { useParams } from "react-router-dom";
import NavbarVisitor from "../Component/NavbarVisitor";
import logo from "../Component/Img/logo.png";
import defaultevent from "../Component/Img/defaultevent.webp";
import { Link } from "react-router-dom";

const Events = () => {
  const { slug } = useParams();
  const [event, setEvent] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [userIp, setUserIp] = useState("");
  const [osDevice, setOsDevice] = useState("");

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`${BASE_URL}event_by_slug/${slug}`);
        if (response.data.event) {
          setEvent(response.data.event);
          trackUserAnalytics(response.data.event.event_unique_id); // Track analytics after event is set
        } else {
          toast.error("Event not found");
        }
      } catch (error) {
        console.error("Error fetching event:", error);
        toast.error("Error fetching event");
      }
    };

    fetchEvent();
  }, [slug]);

  const trackUserAnalytics = async (eventUniqueId) => {
    const ip = await fetchUserIp(); // Fetch user IP
    const os = getOsDevice(); // Get OS device
  
    if (!ip || !os) {
      toast.error("Could not determine user IP or OS device.");
      return;
    }
  
    try {
      const geoResponse = await fetchGeolocation(ip); // Fetch geolocation based on user IP
      if (!geoResponse) {
        toast.error("Could not fetch geolocation.");
        return;
      }
  
      const { city, region, country } = geoResponse;
  
      await axios.post(`${BASE_URL}add-ip-analytics`, {
        user_ip: ip, // Send the user IP
        event_unique_id: eventUniqueId,
        os_device: os,
        geo_location: {
          city,
          region,
          country,
        },
      });
    } catch (error) {
      const message = error.response ? error.response.data.message : error.message;
      toast.error("Error tracking analytics: " + message);
    }
  };
  
  
  // Fetch user IP function modified to return the IP
 
const fetchUserIp = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json"); // Fetch user's IP address
    const ip = response.data.ip;
    setUserIp(ip);
    console.log("User IP Address:", ip); // Log the IP address
    return ip; // Return the IP address
  } catch (error) {
    console.error("Error fetching user IP:", error);
    return null; // Return null on error
  }
};


  // OS device detection
  const getOsDevice = () => {
    const userAgent = window.navigator.userAgent;
    let os = "Unknown OS";

    if (userAgent.indexOf("Win") !== -1) os = "Windows";
    else if (userAgent.indexOf("Mac") !== -1) os = "MacOS";
    else if (userAgent.indexOf("Linux") !== -1) os = "Linux";
    else if (userAgent.indexOf("iPhone") !== -1) os = "iOS";
    else if (userAgent.indexOf("Android") !== -1) os = "Android";

    setOsDevice(os);
    return os; // Return the OS for consistency
  };

  // Fetch geolocation function
  const fetchGeolocation = async (ip) => {
    try {
      const response = await axios.get(`https://ipapi.co/${ip}/json/`); // Example geolocation API
      return response.data; // Return the geolocation data
    } catch (error) {
      console.error("Error fetching geolocation:", error);
      return null; // Return null on error
    }
  };
  

  const handleRatingSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}give_rating`, {
        user_id: 1, // Replace with the actual user ID from your auth logic
        event_unique_id: event.event_unique_id,
        rating: parseInt(rating), // Ensure rating is an integer
        review_text: review,
      });

      toast.success(response.data.message);
      setModalOpen(false);
      setRating(0);
      setReview("");
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        const existingReview = error.response.data.existingReview;

        if (existingReview) {
          toast.info(`Existing Review: Rating: ${existingReview.rating}, Review: "${existingReview.review_text}"`);
        } else {
          toast.error(errorMessage);
        }
      } else {
        toast.error("Error submitting rating");
      }
    }
  };

  const handleFollowEvent = async () => {
    try {
      const response = await axios.post(`${BASE_URL}follow_event`, {
        user_id: 1, // Replace with actual user ID from auth logic
        event_unique_id: event.event_unique_id,
      });

      toast.success(response.data.message);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error following event");
      }
    }
  };

  if (!event) {
    return <div>Loading...</div>;
  }


  return (
    <>
      <NavbarVisitor />
      <div className="container">
        <div className="row mt-3 wdiv">
          <div className="col-md-1 mt-3 align-content-center">
            <img src={logo} alt="Logo" className="img-fluid" />
          </div>
          <div className="col-md-9 mt-3 align-content-center">
            <h2 className="text-start overlay-h1">{event.event_name}</h2>
            <div className="text-start">
              <span className="text-dark mx-3">
                {new Date(event.event_start_date).toLocaleDateString()}
              </span>
              <span className="text-dark mx-3">I'm Interested</span>
              <span className="text-dark mx-3">Share</span>
            </div>
          </div>
          <div className="col-md-2 mt-3 align-content-center">
            <button className="btn btn-warning" onClick={handleFollowEvent}>
              Follow Event
            </button>

            <button
              className="btn btn-warning"
              onClick={() => setModalOpen(true)}
            >
              Give Ratings
            </button>
          </div>
        </div>

        {/* Modal for Rating */}
        {modalOpen && (
          <div className="modal show" style={{ display: "block" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Rate this Event</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setModalOpen(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <div>
                    {[...Array(5)].map((star, index) => {
                      return (
                        <span
                          key={index}
                          onClick={() => setRating(index + 1)}
                          style={{
                            cursor: "pointer",
                            color: index < rating ? "#ffc107" : "#e4e5e9",
                          }}
                        >
                          ★
                        </span>
                      );
                    })}
                  </div>
                  <textarea
                    className="form-control mt-2"
                    rows="3"
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    placeholder="Write your review here..."
                  ></textarea>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setModalOpen(false)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleRatingSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row wdiv mt-3 py-2">
          <div className="col-12 d-flex justify-content-evenly">
            <Link
              className="btn btn-warning"
              to={`/delegate/${event.event_unique_id}`}
            >
              Join As Delegate
            </Link>

            <Link
              className="btn btn-warning"
              to={`/sponser/${event.event_unique_id}`}
            >
              Join As Sponser
            </Link>

            <Link
              className="btn btn-warning"
              to={`/speaker/${event.event_unique_id}`}
            >
              Join As Speaker
            </Link>

            <Link
              className="btn btn-warning"
              to={`/visitor/${event.event_unique_id}`}
            >
              Join As Visitor
            </Link>

            <Link
              className="btn btn-warning"
              to={`/exhibitor/${event.event_unique_id}`}
            >
              Join As Exhibitor
            </Link>
          </div>
        </div>

        <div className="row mt-3">
          {/* left col start */}
          <div className="col-md-8 ">
            <div className="wdiv p-3">
              <img src={defaultevent} alt="Default" className="img-fluid" />
              <div className="aqs1 text-start mt-2">{event.event_name}</div>
              <div className="text-start mt-2">
                {event.event_description || "Description not available"}
              </div>
            </div>
          </div>
          {/* left col end */}

          {/* right col start */}
          <div className="col-md-4 ">
            <div className="wdiv p-3">
              <h4 className="text-start">Event Date</h4>
              <span className="text-start mx-2">
                {new Date(event.event_start_date).toLocaleString()}
              </span>
              <hr />
              <h4 className="text-start">Venue</h4>
              <div className="text-start mx-2">
                {event.event_location || "Location not available"}
              </div>
              <hr />
              <h4 className="text-start">Contact</h4>
              <div className="text-start mx-2">{event.contact_details || "Contact details not available"}</div>
            </div>
          </div>
          {/* right col end */}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Events;
