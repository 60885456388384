import React, { useState, useEffect } from "react";
import axiosInstance from "../../axiosInstance";
import { useEvent } from "../../contexts/EventContext";
import { Link, useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SessionAgenda = () => {
  const { eventId } = useEvent();
  const [sessions, setSessions] = useState([]);
  const [formError, setFormError] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // New state for search input
  const { event_unique_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(`Fetching sessions for eventId: ${eventId}`);
    const fetchSessions = async () => {
      try {
        const response = await axiosInstance.get(`/fetch_sessions_by_eventID/${event_unique_id}`);
        console.log("Fetched sessions:", response.data.sessions);
        setSessions(response.data.sessions);
      } catch (error) {
        console.error("Error fetching sessions:", error);
        setFormError("Error fetching sessions. Please try again.");
      }
    };

    if (event_unique_id) {
      fetchSessions();
    } else {
      console.error("Invalid event_unique_id");
    }
  }, [event_unique_id]);

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/sessions_delete/${id}`);
          setSessions(sessions.filter((session) => session.id !== id));
          toast.success("Session deleted successfully!");
        } catch (error) {
          console.error("Error deleting session:", error);
          setFormError("Error deleting the session. Please try again.");
        }
      }
    });
  };

  // const handleEdit = (id) => {
  //   navigate(`/edit_session/${id}`);
  // };

  // Filter sessions based on the search term
  const filteredSessions = sessions.filter((session) =>
    session.session_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container-dash p-0 position-relative">
      <ToastContainer />
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h3 className="text-start h3txt">Session & Agenda</h3>
                      <Link
                        to={`/event/${event_unique_id}/add-session`} // Link to the Add Session page
                        className="btn btn-primary"
                      >
                        Add Session
                      </Link>
                    </div>

                    {/* Search input field */}
                    <div className="col-md-12 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search by session name..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>

                    {formError && (
                      <div className="alert alert-danger mb-3">{formError}</div>
                    )}
                    {filteredSessions.length > 0 ? (
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Session Name</th>
                            <th scope="col">Session Date</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Action</th> 
                          </tr>
                        </thead>
                        <tbody>
                          {filteredSessions.map((session) => (
                            <tr key={session.id}>
                              <td>{session.session_name}</td>
                              <td>{new Date(session.session_date).toLocaleDateString()}</td>
                              <td>{new Date(session.created_at).toLocaleString()}</td>
                              <td>
<Link to={`/event/${event_unique_id}/update-agenda/${session.id}`} className="btn btn-primary">Update Agenda</Link>

             
                                <button
                                  className="btn btn-danger"
                                  onClick={() => handleDelete(session.id)}
                                >
                                  Delete
                                </button>
                              </td>
                               
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>No sessions found.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionAgenda;
